import React from 'react'
import PropTypes from 'prop-types'
import { Link as GLink } from 'gatsby'
import { Link, Text, get, useThemeUI } from 'theme-ui'
import { GatsbyImage as Img } from 'gatsby-plugin-image'
import rv from '@components/utils/buildResponsiveVariant'
import { css } from 'theme-ui'
import getImageVariant from '@components/utils/getImageVariant'

const styles = {
  link: {
    userSelect: `none`,
    width: `49.5%`,
    height: `0`,
    paddingBottom: `50%`,
    margin:`0 auto`
  }
}

const FeedCard = ({ variantGroup, variant, aside, title, link, slug, ...props }) => {
  const responsiveVariant = rv(variantGroup, variant)
  const { feedImage } = props
  const context = useThemeUI()
  const DEFAULT_IMAGE_VARIANT = 'feed'

  const imageVar =
    get(context.theme, rv(variant, 'imageVariant')[0]) ||
    DEFAULT_IMAGE_VARIANT

  const image = getImageVariant(feedImage, imageVar);

  const linkProps = link
    ? {
        as: 'a',
        href: link,
        target: '_blank',
        rel: 'noopener noreferrer'
      }
    : {
        as: GLink,
        to: slug
      }

  return (
    <Link
      {...linkProps}
      css={styles.link}
      sx={{ variant: rv(variant, 'media') }}
      aria-label={title}
    >
    <Img
      image={image}
      loading='lazy'
      alt={title}
      css={css({
        width: `full`,
        verticalAlign: `middle`, //avoid baseline gap
        img: {
          bg: `omegaLighter`
        },
      })}
    />
    </Link>
  )
}

export default FeedCard

FeedCard.defaultProps = {
  variantGroup: 'cards',
  variant: 'vertical'
}

FeedCard.propTypes = {
  variant: PropTypes.oneOfType([
    PropTypes.array,
    PropTypes.oneOf([
      'horizontal',
      'horizontal-aside',
      'horizontal-md',
      'horizontal-lg',
      'horizontal-cover',
      'horizontal-cover-hero',
      'horizontal-cover-wide',
      'horizontal-hero',
      'vertical-cover',
      'vertical',
      'search'
    ])
  ]),
  omitMedia: PropTypes.bool,
  omitCategory: PropTypes.bool,
  omitExcerpt: PropTypes.bool,
  omitAuthor: PropTypes.bool,
  omitBody: PropTypes.bool,
  omitFooter: PropTypes.bool,
  aside: PropTypes.bool,
  columns: PropTypes.array,
  mediaType: PropTypes.oneOfType([
    PropTypes.bool,
    PropTypes.oneOf(['icon', 'image'])
  ]),
  imageVariant: PropTypes.string,
  loading: PropTypes.oneOf(['lazy', 'auto', 'eager']),
  title: PropTypes.oneOfType([PropTypes.string, PropTypes.element]),
  slug: PropTypes.string,
  link: PropTypes.string,
  category: PropTypes.shape({
    name: PropTypes.string,
    color: PropTypes.string,
    slug: PropTypes.string,
    icon: PropTypes.string
  }),
  author: PropTypes.shape({
    name: PropTypes.string,
    slug: PropTypes.string,
    thumbnail: PropTypes.object
  }),
  date: PropTypes.string,
  timeToRead: PropTypes.number,
  excerpt: PropTypes.oneOfType([PropTypes.string, PropTypes.element]),
  feed: PropTypes.object,
  thumbnail: PropTypes.object,
  thumbnailText: PropTypes.string
}
