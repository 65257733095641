import { graphql } from 'gatsby'
import FeedPage from '../containers/Feed'

export default FeedPage

export const pageQuery = graphql`
  query FeedPageQuery($paginatePostsPage: Boolean!, $skip: Int, $limit: Int) {
    recentPosts: allArticle(
      filter: {
        private: { ne: true }
        draft: {ne: true}
      }
      sort: { fields: [date], order: DESC }
      limit: $limit
      skip: $skip
    ) @skip(if: $paginatePostsPage) {
      nodes {
        ...ArticlePreview
        ...ArticleFeedImageRegular
      }
    }
  }
`
