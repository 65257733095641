import React from 'react'
import { Layout, Stack, Main, Sidebar } from '@layout'
import FeedList from '@components/FeedList'
import Divider from '@components/Divider'
import Seo from '@widgets/Seo'
import NewsletterExpanded from '@widgets/NewsletterExpanded'
import Sticky from '@components/Sticky'

const Feed = ({
  data: { posts = {}, featuredPosts = {}, recentPosts = {} },
  ...props
}) => {
  const { pageContext: { services = {} } = {} } = props

  return (
    <Layout {...props}>
    <Seo title='Feed' />
      <Divider space="1"/>
      <Stack>
        <FeedList
          nodes={recentPosts.nodes}
          columns={[2]}
        />
      </Stack>
    </Layout>
  )
}

export default Feed
